// Core
import ResponsiveEmbed from "react-responsive-embed";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

import {
  Link,
  RichText,
} from "../../components/";

class Sifts extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-results-${data.id}`;

    return (
      <section className={styles.sifts}>
        <div className="anchor" id={section_id}></div>

        <div className="container">
          <div className={styles.sifts__inner}>
            <div className={styles.sifts__text}>
              <h1 className={styles.sifts__title}>{data.title}</h1>
              <p className={styles.sifts__subtitle}>{data.subtitle.subtitle}</p>
              <div className={styles.sifts__description}>
                <RichText json={JSON.parse(data.description.raw)} />
              </div>
              <div className={styles.sifts__button}>
                <Link
                  className="button"
                  to={data.buttonLink}
                >
                  {data.buttonLabel}
                </Link>
              </div>
            </div>
            {data.videoLink &&
              <div className={styles.sifts__video}>
                <ResponsiveEmbed src={data.videoLink} />
              </div>
            }
          </div>
        </div>
      </section>
    );
  }
}

Sifts.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sifts;
