// Core
import PropTypes from "prop-types";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLogin from "react-google-login";

import styles from "./styles.module.css";

import {
  RichText,
} from "../../components/";

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        plan: this.props.data.planType.toLowerCase(),
      },
      popupState: "closed",
    };

    this.recaptchaRef = React.createRef();
    this.host = "https://www.echocast.com";
    this.recaptchaClientId = "6LfWuWoUAAAAANVYCk5NbdVIMpghOyx7ZjqK9ESZ";
    this.googleAuthClientId = "211646614392-iv0ua2mhi0ob8gqfq29rnebv2bg1c8u6.apps.googleusercontent.com";
    this.redirectUrl = false;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleGSSO(response) {
    let data = {
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      idToken: response.tokenId,
      lastName: response.profileObj.familyName,
      plan: this.state.fields.plan,
    };

    this.sendRequest(data, true);
  }

  handleSubmit(event) {
    event.preventDefault();

    let fields = this.state.fields;
    fields["captcha"] = this.recaptchaRef.current.getValue();
    this.setState({ fields });

    if (!fields["captcha"]) {
      alert("Verify you're a human!");
      return false;
    }

    this.sendRequest(fields);
  }

  sendRequest(data, isGoogle) {
    let url = `${this.host}/api/public/v01/registration`;

    if (isGoogle) {
      url = `${this.host}/api/public/v01/google/registration`;
    }

    const requestOptions = {
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    };
    fetch(url, requestOptions)
      .then(async response => {
        const isJson = response.headers.get("content-type")?.includes("application/json");
        const data = isJson && await response.json();

        window.dataLayer = window.dataLayer || [];

        if (response.status == 204) {
          if (isGoogle) {
            this.redirectUrl = `${this.host}/ec/login`;
            window.location.href = this.redirectUrl;
            window.dataLayer.push({
              "event": "sign_up_echocast_sso",
            });
          } else {
            this.redirectUrl = this.host;
            this.openPopup();
            window.dataLayer.push({
              "event": "sign_up_echocast",
            });
          }
        } else if (response.status == 200) {
          if (data.checkout_url) {
            if (isGoogle) {
              window.dataLayer.push({
                "event": "sign_up_echocastplus_sso",
              });
            } else {
              window.dataLayer.push({
                "event": "sign_up_echocastplus",
              });
            }
            window.location.href = data.checkout_url;
          }
        } else {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch(error => {
        alert(error);
        this.recaptchaRef.current.reset();
      });
  }

  openPopup() {
    this.setState({ popupState: "opened" });

    if (this.redirectUrl) {
      setTimeout(() => {
        window.location.href = this.redirectUrl;
      }, 5000);
    }
  }

  closePopup() {
    this.setState({ popupState: "closed" });

    if (this.redirectUrl) {
      window.location.href = this.redirectUrl;
    }
  }

  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-signup-${data.id}`;

    return (
      <section className={styles.signUp}>
        <div className="anchor" id={section_id}></div>

        <div className="container">
          <div className={styles.signUp__text}>
            <h1 className={styles.signUp__title}>{data.title}</h1>
            <div className={styles.signUp__description}>
              <RichText json={JSON.parse(data.description.raw)} />
            </div>
          </div>
          <div className={styles.signUp__formContainer}>
            <div className={styles.signUp__GSSO}>
              <GoogleLogin
                buttonText="Sign up with Google"
                clientId={this.googleAuthClientId}
                cookiePolicy={"single_host_origin"}
                onFailure={this.handleGSSO.bind(this)}
                onSuccess={this.handleGSSO.bind(this)}
              />
              <div className={styles.GSSO__separator}><span>or</span></div>
            </div>
            <form className={styles.signUp__form} onSubmit={this.handleSubmit.bind(this)}>
              <label>
                <span>First Name*</span>
                <input
                  name="firstName"
                  onChange={this.handleChange.bind(this, "firstName")}
                  pattern="^[a-zA-Z]+"
                  required
                  type="text"
                />
                <span className={styles.error_msg}></span>
              </label>
              <label>
                <span>Last Name*</span>
                <input
                  name="lastName"
                  onChange={this.handleChange.bind(this, "lastName")}
                  pattern="^[a-zA-Z]+"
                  required
                  type="text"
                />
              </label>
              <label>
                <span>Email*</span>
                <input
                  name="email"
                  onChange={this.handleChange.bind(this, "email")}
                  required
                  type="email"
                />
              </label>
              <label>
                <span>Password*</span>
                <input
                  name="password"
                  onChange={this.handleChange.bind(this, "password")}
                  pattern=".{6,}"
                  required
                  type="password"
                />
              </label>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={this.recaptchaClientId}
              />
              <button className="button" type="submit">Sign Up for echocast{this.props.data.planType == "Premium" && "+"}</button>
            </form>

            <div className={styles.signUp__popup} data-state={this.state.popupState}>
              <div className={styles.signUp__popupInner}>
                <button onClick={this.closePopup.bind(this)}></button>
                <h4>Please check your email to confirm your registration.</h4>
                <p>And just in case, don&apos;t forget to check your spam, promotions, junk mail, etc folders. You know the drill.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

SignUp.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SignUp;
