// Core
import { graphql } from "gatsby";
import React from "react";
import get from "lodash/get";
// Components
import Layout from "../../layout/";
// Sections
import {
  AboutUs,
  Features,
  HeroCarousel,
  Sifts,
  SignUp,
} from "../../sections/";
const sections = {
  AboutUs: AboutUs,
  Features: Features,
  HeroCarousel: HeroCarousel,
  Sifts: Sifts,
  SignUp: SignUp,
};

class GeneralPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulPageGeneral");

    return (
      <Layout
        seo={{
          description: page_data.description ? page_data.description.description : undefined,
          image: page_data.image.fixed.src,
          path: page_data.path,
          title: page_data.title,
        }}
      >
        {page_data.sections.map((section, index) => {
          const Section = sections[section.internal.type.replace("ContentfulSection", "")];

          return (
            <Section
              data={section}
              key={index}
            />
          );
        })}
      </Layout>
    );
  }
}

export default GeneralPageTemplate;

export const pageQuery = graphql`
  query GeneralPageByPathQuery($path: String!) {
    contentfulPageGeneral(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      image {
        fixed(height: 628, width: 1200) {
          src
        }
      }
      sections {
        ... on Node {
          ... on ContentfulSectionHeroCarousel {
            internal {
              type
            }
            id
            anchorId
            backgroundImage {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            slides {
              title {
                raw
              }
              description {
                raw
              }
              image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              buttonLabel
              buttonLink
            }
          }
          ... on ContentfulSectionAboutUs {
            internal {
              type
            }
            id
            anchorId
            title
            description {
              raw
            }
            videoPoster {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            videoLink
            buttonLabel
            buttonLink
          }
          ... on ContentfulSectionFeatures {
            internal {
              type
            }
            id
            anchorId
            title
            description {
              raw
            }
            featureItems {
              image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulSectionSifts {
            internal {
              type
            }
            id
            anchorId
            title
            subtitle {
              subtitle
            }
            description {
              raw
            }
            videoPoster {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            videoLink
            buttonLabel
            buttonLink
          }
          ... on ContentfulSectionSignUp {
            internal {
              type
            }
            id
            anchorId
            title
            description {
              raw
            }
            planType
          }
        }
      }
    }
  }
`;
