// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

// Components
import {
  RichText,
} from "../../components/";

class Features extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-image-gallery-${data.id}`;

    return (
      <section className={styles.features}>
        <div className="anchor" id={section_id}></div>
        <div className={styles.features__inner}>
          <div className="container">
            <div className={styles.features__text}>
              <h1 className={styles.features__title}>{data.title}</h1>
              <div className={styles.features__description}>
                <RichText json={JSON.parse(data.description.raw)} />
              </div>
            </div>
            <ul className={styles.features__list} data-cols={data.featureItems.length}>
              {data.featureItems.map((item, index) => {
                return (
                  <li
                    className={styles.feature__item}
                    key={index}
                  >
                    {item.image &&
                      <div className={styles.feature__image}>
                        <GatsbyImage
                          alt={item.image.title}
                          image={item.image.gatsbyImageData}
                        />
                      </div>
                    }
                    <div className={styles.feature__description}>
                      <RichText json={JSON.parse(item.description.raw)} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Features.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Features;
