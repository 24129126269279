// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

// Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import {
  Link,
  RichText,
} from "../../components/";

class HeroCarousel extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-hero-${data.id}`;
    const carouselSettings = {
      arrows: false,
      autoplay: false,
      dots: true,
      infinite: true,
      pauseOnFocus: true,
      pauseOnHover: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
    };

    return (
      <section className={styles.heroCarousel}>
        <div className="anchor" id={section_id}></div>
        <div className={styles.heroCarousel__bg}>
          <GatsbyImage
            alt={data.backgroundImage.title}
            image={data.backgroundImage.gatsbyImageData}
          />
        </div>
        <div className={styles.heroCarousel__inner}>
          <Slider className={[styles.heroCarousel__slider, "advanced-carousel"].join(" ")} {...carouselSettings}>
            {data.slides.map((slide, index) => {
              return (
                <div key={index}>
                  <div className={styles.heroSlide}>
                    <div className="container">
                      <div className={styles.heroSlide__top}>
                        <div className={styles.heroSlide__text}>
                          <div className={styles.heroSlide__title}>
                            <RichText json={JSON.parse(slide.title.raw)} />
                          </div>
                          <div className={styles.heroSlide__button}>
                            <Link
                              className="button"
                              to={slide.buttonLink}
                            >
                              {slide.buttonLabel}
                            </Link>
                          </div>
                        </div>
                        <div className={styles.heroSlide__image}>
                          <GatsbyImage
                            alt={slide.image.title}
                            image={slide.image.gatsbyImageData}
                          />
                        </div>
                      </div>
                      <div className={styles.heroSlide__bottom}>
                        <div className={styles.heroSlide__description}>
                          <RichText json={JSON.parse(slide.description.raw)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    );
  }
}

HeroCarousel.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HeroCarousel;
