// Core
import ResponsiveEmbed from "react-responsive-embed";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  Link,
  RichText,
} from "../../components/";

class AboutUs extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-about-us-${data.id}`;

    return (
      <section className={styles.aboutUs}>
        <div className="anchor" id={section_id}></div>

        <div className="container container--small">
          <div className={styles.aboutUs__video}>
            {data.videoLink &&
              <ResponsiveEmbed src={data.videoLink} />
            }
          </div>
          <div className={styles.aboutUs__text}>
            <div className={styles.aboutUs__description}>
              <RichText json={JSON.parse(data.description.raw)} />
            </div>
          </div>
          <div className={styles.aboutUs__button}>
            <Link
              className="button"
              to={data.buttonLink}
            >
              {data.buttonLabel}
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

AboutUs.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutUs;
